import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FourthSectionStyles from './fourthSection.module.css'
import Fade from 'react-reveal/Fade'
import SimpleCard from '../../components/SimpleCard'
import StyledCarousel from '../../components/Carrousel'

export const FourthSection = () => {
  const Data = useStaticQuery(graphql`
    query FouthSectiondEdgeSolutionQuery {
        allStrapiEdgeSolutions {
            edges {
              node {
                data {
                  fourthSection {
                    title
                    content1
                    content2
                    content3
                    content4
                    content5
                  }
                }
                image1sect4 {
                    publicURL
                }
                image2sect4 {
                    publicURL
                }
                image3sect4 {
                    publicURL
                }
                image4sect4 {
                    publicURL
                }
                image5sect4 {
                    publicURL
                }
              }
            }
          }
      }
      
    `)

  const { data, image1sect4, image2sect4, image3sect4, image4sect4, image5sect4, } = Data.allStrapiEdgeSolutions.edges[0].node
  const sectionData = data.fourthSection

  const [index, setIndex] = React.useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section id='ESFourthSect' className={FourthSectionStyles.container}>
      <Fade bottom>
        <h1 className={FourthSectionStyles.title}>{sectionData.title}</h1>
        <div className={FourthSectionStyles.cardsContainer}>
          <SimpleCard imagePath={image1sect4.publicURL} text={sectionData.content1} />
          <SimpleCard imagePath={image2sect4.publicURL} text={sectionData.content2} />
          <SimpleCard imagePath={image3sect4.publicURL} text={sectionData.content3} />
          <SimpleCard imagePath={image4sect4.publicURL} text={sectionData.content4} />
          <SimpleCard imagePath={image5sect4.publicURL} text={sectionData.content5} />
        </div>
      </Fade>
      <div className={FourthSectionStyles.cardsContainerMobile}>

        <StyledCarousel onSelect={handleSelect} activeIndex={index} indicators={false} nextIcon={<span className={FourthSectionStyles.sliderNextIcon} />} prevIcon={<span className={FourthSectionStyles.sliderPrevIcon} />} prevLabel="" nextLabel="">
          <StyledCarousel.Item>
            <SimpleCard imagePath={image1sect4.publicURL} text={sectionData.content1} />
          </StyledCarousel.Item >
          <StyledCarousel.Item >
            <SimpleCard imagePath={image2sect4.publicURL} text={sectionData.content2} />
          </StyledCarousel.Item>
          <StyledCarousel.Item >
            <SimpleCard imagePath={image3sect4.publicURL} text={sectionData.content3} />
          </StyledCarousel.Item>
          <StyledCarousel.Item >
            <SimpleCard imagePath={image4sect4.publicURL} text={sectionData.content4} />
          </StyledCarousel.Item>
          <StyledCarousel.Item >
            <SimpleCard imagePath={image5sect4.publicURL} text={sectionData.content5} />
          </StyledCarousel.Item>
        </StyledCarousel>
      </div>
    </section>
  )
}

export default FourthSection