//  libraries
import React from 'react'

//  containers
import FirtSection from '../containers/EdgeSolutions/firstSection'
import SecondSection from '../containers/EdgeSolutions/secondSection'
import ThirdSection from '../containers/EdgeSolutions/thirdSection'
import FourthSection from '../containers/EdgeSolutions/fourthSection'
import FifthSection from '../containers/EdgeSolutions/fifthSection'
import SixthSection from '../containers/EdgeSolutions/sixthSection'
import SevthSection from '../containers/EdgeSolutions/sevthSection'
import EighthSection from '../containers/EdgeSolutions/eighthSection'

//  context

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'

const EdgeSolutions = () => {
  return (
    <Layout>
      <SEO
        title="Truck Freight Broker with Digital Freight Management Solutions | Edge"
        description="Edge Logistics a leading truck freight broker and digital supply chain provider. See a demo of our easy, all-in-one CAPACITY freight management platform."
        keywords={[
          'Truck freight broker',
          'digital supply chain',
          'freight management platform'
        ]}
      />
      <FirtSection />
      {/* <SecondSection /> */} {/** CAPACITY */}
      {/* <ThirdSection /> */} {/** CAPACITY */}
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <SevthSection />
      <EighthSection />
    </Layout>
  )
}

export default EdgeSolutions
