import React from 'react'
import secondSectionStyles from './secondSection.module.css'
import { graphql, useStaticQuery, Link } from 'gatsby'
import CustomButton from './../../components/CustomButton'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { moveInLeft } from 'styles/animation'
import Fade from 'react-reveal/Fade'

const Section = styled.section`
  ${moveInLeft('.8s', 'ease-out')}
`

const StyledBackgrounImage = styled(BackgroundImage)`
  background-position: 0 0;
`

const SecondSection = () => {
  const Data = useStaticQuery(graphql`
    query SecondSectiondEdgeSolutionQuery {
      allStrapiEdgeSolutions {
        edges {
          node {
            data {
              secondSection {
                description
                button
                buttonURL
                imageAltText
              }
            }
            image1sect3 {
              publicURL
            }
            image2sect3 {
              publicURL
            }
            mobileImgSect3 {
              publicURL
            }
            CircleSect3 {
              publicURL
            }
            circleIpad {
              publicURL
            }
            arrowSect3 {
              publicURL
            }
            LogoEdgeCapacity {
              publicURL
            }
          }
        }
      }
    }
  `)

  const {
    data,
    image1sect3,
    image2sect3,
    CircleSect3,
    circleIpad,
    arrowSect3,
    mobileImgSect3,
    LogoEdgeCapacity
  } = Data.allStrapiEdgeSolutions.edges[0].node
  const sectionData = data.secondSection
  return (
    <Section id="ESSecondSect" className={secondSectionStyles.container}>
      <section className={secondSectionStyles.inner}>
        <div className={secondSectionStyles.left}>
          <section className={secondSectionStyles.leftInner}>
            <img
              src={LogoEdgeCapacity.publicURL}
              alt={sectionData.imageAltText}
              className={secondSectionStyles.capacityLogo}
            />
            <img
              src={mobileImgSect3.publicURL}
              className={secondSectionStyles.mobileImg}
              alt=""
            />
            <p className={secondSectionStyles.description}>
              {sectionData.description}
            </p>
            <Link to={sectionData.buttonURL}>
              <CustomButton
                text={sectionData.button}
                className={secondSectionStyles.button}
              />
            </Link>
          </section>
        </div>
        <div className={secondSectionStyles.right}>
          <img
            src={CircleSect3.publicURL}
            className={secondSectionStyles.circle}
            alt=""
          />

          <Fade bottom cascade>
            <img
              src={image1sect3.publicURL}
              className={secondSectionStyles.image1}
              alt=""
            />
            <img
              src={image2sect3.publicURL}
              className={secondSectionStyles.image2}
              alt=""
            />
          </Fade>
        </div>
      </section>
    </Section>
  )
}

export default SecondSection
