import React from 'react';
import eighthSectionStyles from './eighthSection.module.css';
import { graphql, useStaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';


const EighthSection = () => {
  const Data = useStaticQuery( graphql`
    query EighthSectionEdgeSolutionQuery {
      allStrapiEdgeSolutions {
        edges {
          node {
            data {
              eighthSection {
                title2
                title
                content
              }
            }
            image1sect6 {
              publicURL
            }
          }
        }
      }
    }
  ` );
  const { data, image1sect6 } = Data.allStrapiEdgeSolutions.edges[0].node;
  const sectionData = data.eighthSection;

  return (
    <section id="ESEighthSect" className={ eighthSectionStyles.container }>
      <div className={ eighthSectionStyles.wrapper }>
        <Fade left>
          <div className={ eighthSectionStyles.left }>
            <img
              src={ image1sect6.publicURL }
              className={ eighthSectionStyles.img }
              alt="image1"
            />
          </div>
        </Fade>
        <Fade>
          <div className={ eighthSectionStyles.right }>
            <h1 className={ eighthSectionStyles.title }>{ sectionData.title }</h1>
            <h2 className={ eighthSectionStyles.title2 }>{ sectionData.title2 }</h2>
            <p className={ eighthSectionStyles.content }>{ sectionData.content }</p>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default EighthSection;
