import React from 'react';
import sixthSectionStyles from './sixthSection.module.css';
import { graphql, useStaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


const SixthSection = () => {
  const Data = useStaticQuery( graphql`
    query SixthSectiondEdgeSolutionQuery {
      allStrapiEdgeSolutions {
        edges {
          node {
            data {
              sixthSection {
                title
                title2
                item1
                item2
                item3
              }
            }
            sixthSectionImage {
              publicURL
            }
          }
        }
      }
    }
  ` );
  const { data, sixthSectionImage } = Data.allStrapiEdgeSolutions.edges[0].node;
  const sectionData = data.sixthSection;
  return (
    <section id="ESSixthSect" className={ sixthSectionStyles.container }>
      <div className={ sixthSectionStyles.wrapper }>
        <Fade left>
          <div className={ sixthSectionStyles.left }>
            <img
              src={ sixthSectionImage.publicURL }
              className={ sixthSectionStyles.img }
              alt="image1"
            />
          </div>
        </Fade>
        <Fade>
          <div className={ sixthSectionStyles.right }>
            <h1 className={ sixthSectionStyles.title }>{ sectionData.title }</h1>
            <h2 className={ sixthSectionStyles.title2 }>{ sectionData.title2 }</h2>
            <div className={ sixthSectionStyles.iconItem }>
              <FontAwesomeIcon
                icon={ faCheck }
                inverse
                transform="shrink-6"
                style={ {
                  fontSize: '17px',
                  color: '#a0c7de',
                  marginRight: '20px',
                } }
              />
              <p className={ sixthSectionStyles.item }>{ sectionData.item1 }</p>
            </div>
            <div className={ sixthSectionStyles.iconItem }>
              <FontAwesomeIcon
                icon={ faCheck }
                inverse
                transform="shrink-6"
                style={ {
                  fontSize: '17px',
                  color: '#a0c7de',
                  marginRight: '20px',
                } }
              />
              <p className={ sixthSectionStyles.item }>{ sectionData.item2 }</p>
            </div>
            <div className={ sixthSectionStyles.iconItem }>
              <FontAwesomeIcon
                icon={ faCheck }
                inverse
                transform="shrink-6"
                style={ {
                  fontSize: '17px',
                  color: '#a0c7de',
                  marginRight: '20px',
                } }
              />
              <p className={ sixthSectionStyles.item }>{ sectionData.item3 }</p>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default SixthSection;
