import React from 'react';
import seventhSectionStyles from './sevthSection.module.css';
import { graphql, useStaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';


const SeventhSection = () => {
  const Data = useStaticQuery( graphql`
    query FifthSectiondEdgeSolutionQuery {
      allStrapiEdgeSolutions {
        edges {
          node {
            data {
              sevthSection {
                title2
                title
                content
              }
            }
            image1sect5 {
              publicURL
            }
          }
        }
      }
    }` );
  const { data, image1sect5 } = Data.allStrapiEdgeSolutions.edges[0].node;
  const sectionData = data.sevthSection;
  return (
    <section id="ESSeventhSect" className={ seventhSectionStyles.container }>
      <section className={ seventhSectionStyles.inner }>
        <Fade>
          <div className={ seventhSectionStyles.left }>
            <h1 className={ seventhSectionStyles.title }>{ sectionData.title }</h1>
            <h1 className={ seventhSectionStyles.title2 }>{ sectionData.title2 }</h1>
            <p className={ seventhSectionStyles.content }>{ sectionData.content }</p>
          </div>
        </Fade>
        <Fade left>
          <div className={ seventhSectionStyles.right }>
            <img src={ image1sect5.publicURL } className={ seventhSectionStyles.img } alt="image1"/>
          </div>
        </Fade>
      </section>
    </section>
  );
};

export default SeventhSection;