import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const BackgroundContainer = styled(BackgroundImage)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 127px 0 88px;
  
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }
`;

export default BackgroundContainer;
