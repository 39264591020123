import React from 'react'
import PropTypes from 'prop-types'
import cardStyles from './index.module.css'

const SimpleCard = ({imagePath, text} ) => {
    return (
        <div className={cardStyles.container}>
            <img className={cardStyles.img} src={imagePath} width="84" height='85' alt="apiservices"/>
            <h3 className={cardStyles.title}>{text}</h3>
        </div>
    )
}

SimpleCard.propTypes = {
    iamgePath: PropTypes.string,
    text: PropTypes.string
}

export default SimpleCard
