import React from 'react';
import fifthSectionStyles from './fifthSections.module.css';
import { graphql, useStaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';


const FifthSection = () => {
  const Data = useStaticQuery( graphql`
    query FifthSectionEdgeSolutionQuery {
      allStrapiEdgeSolutions {
        edges {
          node {
            data {
              fifthSection {
                title2
                title
                content
              }
            }
            fifthSectionImage {
              publicURL
            }
          }
        }
      }
    }
  ` );

  const { fifthSectionImage } = Data.allStrapiEdgeSolutions.edges[0].node;

  const sectionData = Data.allStrapiEdgeSolutions.edges[0].node.data.fifthSection;

  return (
    <section id="ESFifthSect" className={ fifthSectionStyles.container }>
      <section className={ fifthSectionStyles.inner }>
        <Fade>
          <div className={ fifthSectionStyles.left }>
            <h1 className={ fifthSectionStyles.title }>{ sectionData.title }</h1>
            <h1 className={ fifthSectionStyles.title2 }>{ sectionData.title2 }</h1>
            <p className={ fifthSectionStyles.content }>{ sectionData.content }</p>
          </div>
        </Fade>
        <Fade left>
          <div className={ fifthSectionStyles.right }>
            <img src={ fifthSectionImage.publicURL } className={ fifthSectionStyles.img } alt="image1"/>
          </div>
        </Fade>
      </section>
    </section>
  );
};

export default FifthSection;