import React from 'react'
import firtsSectionStyles from './firstSection.module.css'
import styled from 'styled-components'
import { moveInLeft } from 'styles/animation'
import { graphql, useStaticQuery, Link } from 'gatsby'

import BackgroundContainer from './firstSection.style'

const Section = styled.section`
  ${moveInLeft('.8s', 'ease-out')}
`

const FirstSection = () => {
  const Data = useStaticQuery(graphql`
    query FirstSectiondEdgeSolutionQuery {
      allStrapiEdgeSolutions {
        edges {
          node {
            data {
              firstSection {
                title
                content
                button1
                button1URL
                button2
                button2URL
              }
            }
            headerSect1 {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const { data, headerSect1 } = Data.allStrapiEdgeSolutions.edges[0].node
  const sectionData = data.firstSection
  return (
    <section id="ESHeader">
      <BackgroundContainer fluid={headerSect1.childImageSharp.fluid}>
        <Section>
          <h1 className={firtsSectionStyles.title}> {sectionData.title} </h1>
          <p className={firtsSectionStyles.content}>{sectionData.content}</p>
          <div className={firtsSectionStyles.buttonsDiv}>
            <Link to={sectionData.button1URL}>
              <button className={firtsSectionStyles.button}>
                {sectionData.button1}
              </button>
            </Link>
            <Link to={sectionData.button2URL}>
              <button className={firtsSectionStyles.button}>
                {sectionData.button2}
              </button>
            </Link>
          </div>
        </Section>
      </BackgroundContainer>
    </section>
  )
}

export default FirstSection
